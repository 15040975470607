import React from 'react';
import Container from '@components/Container';

import Item from './Item';

import styles from './styles.module.css';
import data from './data.js';

export default () => (
  <section className={styles.container}>
    <Container>
      <h3 className={styles.title}>{data.title}</h3>
      <div className={styles.offerings}>
        {data.items.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </div>
    </Container>
  </section>
);
