import {
  MusicNote2,
  Download1,
  Court2,
  Settings2,
  Cash1,
  Microphone2,
} from '@icons';

export default {
  title: 'Что мы предлагаем',
  items: [
    {
      Icon: MusicNote2,
      title: 'Широкий выбор музыки',
      text:
        'В нашей медиатеке огромное количество музыкальных композиций, объединённых в тематические плейлисты по жанрам, тематическим праздникам и типам заведений.',
    },
    {
      Icon: Download1,
      title: 'Лёгкое подключение',
      text:
        'Пользоваться нашим сервисом можно через удобный онлайн-плеер или просто скачать понравившиеся плейлисты на своё устройство.',
    },
    {
      Icon: Court2,
      title: 'Лицензионные материалы',
      text:
        'Вся музыка, размещённая в нашем сервисе, имеет разрешение правообладателей на воспроизведение и публичное исполнение.',
    },
    {
      Icon: Settings2,
      title: 'Круглосуточная поддержка',
      text:
        'Для наших клиентов мы оказываем круглосуточную профессиональную техническую и юридическую поддержку.',
    },
    {
      Icon: Cash1,
      title: 'Выгодная стоимость',
      text:
        'Мы работаем напрямую с правообладателями, что позволяет существенно сократить расходы на проигрывание музыки и не выплачивать вознаграждение РАО и ВОИС.',
    },
    {
      Icon: Microphone2,
      title: 'Свои аудиозаписи',
      text:
        'Используя онлайн-плеер IMstream, Вы можете добавить в свой музыкальный эфир дополнительные аудиозаписи, например, рекламные материалы.',
    },
  ],
};
