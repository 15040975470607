import React from 'react';

import Container from '@components/Container';
import CardRow from '@components/ServiceCardRow';

import { title } from './data.json';
import styles from './styles.module.css';

export default function() {
  return (
    <div className={styles.container}>
      <Container>
        <b className={styles.title}>{title}</b>
        <CardRow />
      </Container>
    </div>
  );
}
