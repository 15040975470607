import React from 'react';

import Container from '@components/Container';

import styles from './styles.module.css';
import { title, text } from './data.json';

export default function() {
  return (
    <section className={styles.container}>
      <Container>
        <p className={styles.title}>{title}</p>
        <p className={styles.paragraph}>{text}</p>
      </Container>
    </section>
  );
}
