import React from 'react';
import styles from './styles.module.css';

export default ({ Icon, title, text }) => (
  <div className={styles.container}>
    <Icon height={40} width={40} className={styles.icon} />
    <p className={styles.title}>{title}</p>
    <p className={styles.description}>{text}</p>
  </div>
);
