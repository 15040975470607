import React from 'react';
import Slider from 'react-slick';

import Container from '@components/Container';
import styles from './styles.module.css';

import settings from './settings';

import Cinnabon from '@svg/cinnabon.inline.svg';
import Harley from '@svg/harley-davidson.inline.svg';
import Hesburger from '@svg/hesburger.inline.svg';
import Marchellis from '@svg/marchellis.inline.svg';
import Marketplace from '@svg/marketplace.inline.svg';
import Subway from '@svg/subway.inline.svg';
import Sportlife from '@svg/sportlife.inline.svg';

const logos = [
  {
    Logo: Cinnabon,
    height: 40,
  },
  {
    Logo: Harley,
    height: 70,
  },
  {
    Logo: Hesburger,
    height: 35,
  },
  {
    Logo: Sportlife,
    height: 45,
    width: 97.5,
  },
  {
    Logo: Marchellis,
    height: 40,
  },
  {
    Logo: Marketplace,
    height: 40,
  },
  {
    Logo: Subway,
    height: 40,
  },
];

const Carousel = () => (
  <Slider {...settings}>
    {logos.map(({ Logo, height, width }, index) => (
      <div key={index}>
        <div className={styles.wrapper}>
          <Logo className={styles.logo} height={height} width={width} />
        </div>
      </div>
    ))}
  </Slider>
);

export default () => (
  <div className={styles.container}>
    <Container className={styles.inner}>
      <h2 className={styles.title}>Уже с нами</h2>
      <div className={styles.slick}>
        <Carousel />
      </div>
    </Container>
  </div>
);
