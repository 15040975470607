import React from 'react';

import Card from './Card';
import { Cafe1, Shop1, Fitness1 } from '@icons';

import styles from './styles.module.css';
import { cafe, fitness, shop } from '@data/navigation.json';
import { cards, button } from './data.json';

const data = {
  cafe: {
    Icon: Cafe1,
    href: cafe.href,
  },
  fitness: {
    Icon: Fitness1,
    href: fitness.href,
  },
  shop: {
    Icon: Shop1,
    href: shop.href,
  },
};

function createCard({ name, title, description }) {
  const { Icon, href } = data[name];
  return (
    <Card
      key={name}
      title={title}
      description={description}
      button={button}
      Icon={Icon}
      href={href}
    />
  );
}

const Row = () => cards.map(createCard);

export default () => (
  <div className={styles.container}>
    <Row />
  </div>
);
