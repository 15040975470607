import React, { Fragment } from 'react';

import CTA from '@src/components/CallToAction';

import Welcome from './Welcome';
import Introduction from './Introduction';
import Carousel from './Carousel';
import Offerings from './Offerings';
import Examples from './Examples';

export default () => (
  <Fragment>
    <Welcome />
    <Introduction />
    <Offerings />
    <Carousel />
    <Examples />
    <CTA />
  </Fragment>
);
