import React from 'react';
import { Link } from 'gatsby';
import styles from './styles.module.css';

const size = 35;

export default function({ Icon, title, description, button, href }) {
  return (
    <div className={styles.container}>
      <Icon height={size} width={size} className={styles.icon} />
      <b className={styles.title}>{title}</b>
      <p className={styles.description}>{description}</p>
      <div className={styles.footer}>
        <Link to={href} className={styles.link}>
          {button}
        </Link>
      </div>
    </div>
  );
}
