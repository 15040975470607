import React from 'react';
import Body from '@sections/Main';
import Layout from '@layouts/business';
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO
      title="IMstream | Музыка для бизнеса"
      description="IMstream - сервис по предоставлению профессионального музыкального оформления для различных сфер бизнеса. Без выплат РАО и ВОИС."
    />
    <Body />
  </Layout>
);
