import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import { useWindowWidth } from '@src/utils/hooks';

import styles from './styles.module.css';
import data from './data.json';
import { GetOfferButtonWithModal } from '@src/ui';

export default function() {
  const {
    desktop: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "welcome-background.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const { isMobile } = useWindowWidth();
  const { title, subtitle } = data;

  return (
    <BackgroundImage
      className={styles.container}
      Tag={'section'}
      fluid={fluid}
      backgroundColor={`grey`}
    >
      <div className={styles.header}>
        <h1
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: isMobile ? title.mobile : title.default,
          }}
        />
        <h2
          className={styles.subtitle}
          dangerouslySetInnerHTML={{
            __html: isMobile ? subtitle.mobile : subtitle.default,
          }}
        />
      </div>
      <GetOfferButtonWithModal />
    </BackgroundImage>
  );
}
